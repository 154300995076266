import React from 'react'
import _ from 'lodash'
import { Dropdown, message } from 'antd'
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons'

import axiosRequest from '../../../../hooks/useAxiosRequest'
import { GalleryItem } from './GalleryItem'
import axios from 'axios'

const UploadImages = ({ gallery, setGallery }) => {
  const imageUploadHandler = async (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0]
      message.loading({ content: 'Please, wait...', key: 'save' })
      const formData = new FormData()
      formData.append('image', file)
      formData.append('asDefaultImage', '1')

      const fileBuffer = Buffer.from(await file.arrayBuffer()).toJSON().data
      const { data } = await axios.post('https://editorial.tradeto.world/api/image-upload', {
        file: fileBuffer,
      })
      if (data.locations) {
        const galleryArr = gallery
        galleryArr.push(data.locations[0])
        setGallery([...galleryArr])
        message.destroy()
        e.target.value = ''
      }

      // axiosRequest('/api/v1/images/upload', formData, 'post', true).then((res) => {
      //   if (res.data.image) {
      //     const { original } = JSON.parse(res.data.image)
      //     const galleryArr = gallery
      //     galleryArr.push(original)
      //     setGallery([...galleryArr])
      //     message.destroy()
      //     e.target.value = ''
      //   }
      // })
    } else {
      message.error('Incorrect image')
    }
  }

  const removeImage = (img) => {
    const galleryArr = gallery
    _.remove(galleryArr, (n) => {
      return n === img
    })
    setGallery([...galleryArr])
  }

  return (
    <div className="col-12">
      <p>
        <strong>Photo gallery</strong>
        <Dropdown
          overlayClassName="photo-gallery__info__overlay"
          overlay={
            "Although not mandatory, we highly recommend the use of images to display your business' identity and further its credibility."
          }
        >
          <InfoCircleOutlined className="photo-gallery__info__icon" />
        </Dropdown>
      </p>
      <div className="additional-info-gallery">
        <div className="gallery__wrapper">
          <div className="gallery__item gallery__upload">
            <input type="file" accept="image/*" onChange={(e) => imageUploadHandler(e)} />
            <p className="mb-0">
              <PlusOutlined /> Add photo
            </p>
          </div>
          {gallery.map((image) => {
            return <GalleryItem onRemove={removeImage} image={image} key={image} />
          })}
        </div>
      </div>
    </div>
  )
}

export default UploadImages
