import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import { Form, message } from 'antd'

import axiosRequest from '../../../hooks/useAxiosRequest'
import { getVerificationSteps } from '../../../data/verficationSteps'
import { updateAccountStatus } from '../../../util/functions/updateAccountStatus'

import LayoutLoading from '../../../components/verification/LayoutLoading'
import VerificationLayout from '../../../components/verification/VerificationLayout'
import UploadImages from '../../../components/verification/steps/additional-info/UploadImages'
import UploadDocs from '../../../components/verification/steps/additional-info/UploadDocs'
import { VerificationModal } from '../../../components/ui/modals/VerificationModal'

const CompanyAdditionalInformation = (props) => {
  const pageTitle = 'Photos & Documents'
  const [form] = Form.useForm()
  const { user } = useSelector((state) => state.user)
  const [progress, setProgress] = useState(0)
  const [info, setInfo] = useState({})
  const [gallery, setGallery] = useState([])
  const [docs, setDocs] = useState([])
  const [loading, setLoading] = useState(true)
  const [isShowModal, setIsShowModal] = useState(false)

  const handleCloseModal = () => {
    setIsShowModal(false)
  }

  useEffect(() => {
    axiosRequest('/api/v1/on-boarding/company-additional-info', null, 'get', true).then(
      (res) => {
        if (res.data.success) {
          const totalSteps = getVerificationSteps()
          setInfo(res.data.company)
          setProgress(parseInt((res.data.steps.length * 100) / totalSteps.length))
          setLoading(false)
          if (res.data.company.get_images.length > 0) {
            const images = []
            res.data.company.get_images.forEach((image) => {
              images.push(image.image)
            })
            setGallery(images)
          }
          if (res.data.company.get_docs.length > 0) {
            const docs = []
            res.data.company.get_docs.forEach((doc) => {
              docs.push({ id: doc.id, name: doc.name, file: doc.path, type: doc.type })
            })
            setDocs(docs)
          }
        }
      },
    )
  }, [])

  const onSubmit = (values) => {
    if (gallery.length < 1) {
      message.error('Upload at least 1 pictures')
      return null
    }
    const data = {
      ...values,
      gallery,
      docs,
    }
    message.loading({ content: 'Please, wait...', key: 'save' })

    axiosRequest('/api/v1/on-boarding/photos-docs', data, 'post', true)
      .then((res) => {
        if (res.data.success) {
          updateAccountStatus(3, user.id)
          message.destroy()
          setIsShowModal(true)
        }
      })
      .catch(() => {
        message.error('Something goes wrong, try again or contact support...')
      })
  }

  const onFinishFail = () => {
    message.error('Something goes wrong, contact support...')
  }

  if (loading || !info.id) return <LayoutLoading />

  return (
    <>
      <VerificationModal isShow={isShowModal} onCancel={handleCloseModal} />
      <Helmet title={pageTitle} />
      <Form
        form={form}
        onFinish={onSubmit}
        onFinishFailed={onFinishFail}
        layout="vertical"
        hideRequiredMark
      >
        <VerificationLayout currentStep="photosDocs" progress={progress}>
          <h1>{pageTitle}</h1>
          <div className="row">
            <UploadImages gallery={gallery} setGallery={setGallery} />
            <UploadDocs docs={docs} setDocs={setDocs} />
          </div>
        </VerificationLayout>
      </Form>
    </>
  )
}

export default CompanyAdditionalInformation
